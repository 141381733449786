import { useReducer } from "react";

const initialInputState = {
  value: "",
  isTouched: false,
  isFocused: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    let isTouched = state.isTouched
    if (action.value.length === 0 && isTouched) {
      isTouched = false;
    }
    return { value: action.value, isTouched: state.isTouched, isFocused: state.isFocused };
  }
  if (action.type === "BLUR") {
    let isTouched = false;
    if (state.value.length > 0) {
      isTouched = true;
    }
    return { isTouched: isTouched, isFocused: false, value: state.value };
  }
  if (action.type === "RESET") {
    return { isTouched: false, isFocused: false, value: "" };
  }
  if (action.type === "FOCUS") {
    return { isFocused: true, isTouched: state.isTouched, value: state.value };
  }
  return inputStateReducer;
};

const useInput = (validateValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const error = validateValue(inputState.value);

  let showError = error && inputState.isTouched && inputState.value.length > 0;

  const valueChangeHandler = (event) => {
    dispatch({ type: "INPUT", value: event.target.value });
  };

  const inputBlurHandler = (event) => {
    dispatch({ type: "BLUR" });
  };

  const inputFocusHandler = (event) => {
    event.preventDefault();
    dispatch({ type: "FOCUS" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  return {
    value: inputState.value,
    isTouched: inputState.isTouched,
    error,
    showError,
    valueChangeHandler,
    inputBlurHandler,
    inputFocusHandler,
    reset,
  };
};

export default useInput;
