import urls from "../../lib/urls";
import Button from "../core/Button";
import Text from "../core/Text";
import formStyles from "../core/css/Form.module.css";

const EmailSent = ({ children, onClick }) => {
  return (
    <div className={formStyles["form-wrapper"]}>
      <Text style="h5" className={"center " + formStyles.heading}>
        Email sent 📬
      </Text>
      <Text
        style="body-medium"
        className={"center " + formStyles["subheading"]}
      >
        {children}
      </Text>
      <form>
        <Button
          size="large"
          linkType="internal"
          replace
          to={urls.login}
          onClick={onClick}
        >
          Back to sign in
        </Button>
      </form>
    </div>
  );
};

export default EmailSent;
