import { useSearchParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import Button from "../components/core/Button";
import { deleteUser as deleteDatabaseUser } from "../lib/api";
import { deleteUser as deleteFirebaseUser } from "firebase/auth";
import WebPlayback from "../components/spotify/WebPlayback";
import ConnectButton from "../components/spotify/ConnectButton";
import { getSpotifyToken } from "../lib/api";
import { getFirstName } from "../lib/utils";

const auth = getAuth();

const FakeDashboard = (props) => {

  const [queryParams, setQueryParams] = useSearchParams();
  // this eventually needs to be stored locally rather than read from the url each time
  // we probably want to clear the param from the url for UX reasons
  const spotifyAuthCode = queryParams.get('code')
  const [firstName, setFirstName] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [token, setToken] = useState('');

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setFirstName(getFirstName(user.displayName));
        setEmailVerified(user.emailVerified);
        console.log(user);
        console.log(user.emailVerified);
      }
    });
  }, []);

  useEffect(() => {
    if (spotifyAuthCode) {
      console.log("Spotify auth code: " + spotifyAuthCode)
      const token = getSpotifyToken(spotifyAuthCode)
      setToken(token)
    }
  }, [spotifyAuthCode]);

  const deleteAccountHandler = (event) => {
    event.preventDefault();
    const uid = auth.currentUser.uid;
    deleteDatabaseUser(uid)
      .then((response) => deleteFirebaseUser(auth.currentUser))
      .catch((error) => console.log(error));
  };

  return (
    <>
      <h1>Welcome, {firstName}!</h1>
      <p>Email verified: {`${emailVerified}`}</p>
      <Button onClick={deleteAccountHandler}>Delete account</Button>
      {token === '' ? <ConnectButton /> : <WebPlayback token={token}/>}
    </>
  );
};

export default FakeDashboard;
