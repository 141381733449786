import CenterAlignedCard from "../ui/CenterAlignedCard";
import useFormControl from "../../hooks/useFormControl";
import Button from "../core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isUsernameTaken } from "../../lib/api";
import { authActions, createUserThunk } from "../../store/auth-slice";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../ui/LoadingSpinner";
import Text from "../core/Text";
import formStyles from "../core/css/Form.module.css";
import textStyles from "../core/css/text.module.css";

const CreateUsernameForm = (props) => {
  console.log("Rending CreateUsernameForm");

  const [usernameTaken, setUsernameTaken] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  const destUrl = useSelector((state) => state.auth.destUrl);

  const {
    formControl: username,
    resetInput: resetUsername,
    error: usernameError,
    inputValue: usernameValue,
    inputTouched: usernameTouched,
  } = useFormControl(
    {
      inputId: "username-input",
      type: "text",
      label: "Username *",
      size: "large",
      validator: (value) => {
        if (usernameTaken) {
          return "This username is already taken";
        }
      },
    }
  );

  const disableSubmit =
    usernameTaken !== false ||
    usernameValue.trim() === "" ||
    status == "pending";

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      if (usernameValue.trim() !== "") {
        try {
          const taken = await isUsernameTaken(usernameValue, controller);
          setUsernameTaken(taken);
        } catch (error) {
          console.log(error);
        }
      }
    };
    const timer = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [usernameValue, usernameTouched]);

  const submitHandler = (event) => {
    console.log("in submit handler");
    event.preventDefault();
    const userData = {
      username: usernameValue,
    };
    dispatch(createUserThunk(userData));
  };

  useEffect(() => {
    if (status === "success") {
      resetUsername();
      navigate(destUrl);
      dispatch(authActions.setExistsInDb(true));
      dispatch(authActions.resetDestUrl());
      dispatch(authActions.resetStatus());
    }
  }, [status]);

  useEffect(() => {
    if (status === "failure") {
      console.log(error);
      return () => dispatch(authActions.resetStatus());
    }
  }, [status, error]);

  let content = (
    <div className={formStyles["form-wrapper"]}>
      <form onSubmit={submitHandler}>
        <Text style="h5" className={"center " + formStyles.heading}>
          Create new account
        </Text>
        {username}
        <ul
          className={"registration-hints fine-print " + formStyles.subheading}
        >
          <li>
            <Text tag="span" style="body-small">
              (*) Required field
            </Text>
          </li>
          <li>
            <Text tag="span" style="body-small">
              You can always change your username later
            </Text>
          </li>
        </ul>
        <Button size="large" disabled={disableSubmit}>
          Create account
        </Button>
        {status === "failure" && (
          <Text
            style="body-small"
            color="error"
            className="center"
          >
            An error has occurred. Please try again later.
          </Text>
        )}
      </form>
    </div>
  );

  if (status === "pending") {
    content = <LoadingSpinner />;
  }
  return <CenterAlignedCard>{content}</CenterAlignedCard>;
};

export default CreateUsernameForm;
