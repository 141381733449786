import { generateRandomString } from "../../lib/utils";
import Button from "../core/Button";

const ConnectButton = (props) => {

    const state = generateRandomString(16)
    const url = "https://accounts.spotify.com/authorize/?" + new URLSearchParams({
        client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        response_type: "code",
        scope: "streaming user-read-private user-read-email ugc-image-upload",
        redirect_uri: process.env.REACT_APP_HOST,
        state: state
    })

    console.log("CLIENT ID: " + process.env.REACT_APP_SPOTIFY_CLIENT_ID)

    return <Button linkType="external" href={url}>Connect to Spotify</Button>
}

export default ConnectButton;