const GoogleIcon = (props) => {
  return (
    <div id="google-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9_549)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.2 20.4546C39.2 19.0364 39.0727 17.6727 38.8364 16.3636H20V24.1H30.7636C30.3 26.6 28.8909 28.7182 26.7727 30.1364V35.1546H33.2364C37.0182 31.6727 39.2 26.5455 39.2 20.4546Z"
            fill="#4285F4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 40C25.4 40 29.9273 38.2091 33.2364 35.1545L26.7728 30.1364C24.9819 31.3364 22.691 32.0454 20 32.0454C14.791 32.0454 10.3819 28.5273 8.80914 23.8H2.12732V28.9818C5.41823 35.5182 12.1819 40 20 40Z"
            fill="#34A853"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.80909 23.8C8.40909 22.6 8.18182 21.3182 8.18182 20C8.18182 18.6818 8.40909 17.4 8.80909 16.2V11.0182H2.12727C0.772727 13.7182 0 16.7727 0 20C0 23.2273 0.772727 26.2818 2.12727 28.9818L8.80909 23.8Z"
            fill="#FBBC05"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 7.95455C22.9364 7.95455 25.5728 8.96364 27.6455 10.9455L33.3819 5.20909C29.9182 1.98182 25.391 0 20 0C12.1819 0 5.41823 4.48182 2.12732 11.0182L8.80914 16.2C10.3819 11.4727 14.791 7.95455 20 7.95455Z"
            fill="#EA4335"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_549">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default GoogleIcon;
