import axios from "axios";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";

import NotFound from "./pages/NotFound";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import NavBar from "./components/layout/NavBar";
import ForgotPassword from "./pages/ForgotPassword";
import CreateNewPassword from "./pages/CreateNewPassword";
import FakeDashboard from "./pages/FakeDashboard";
import ProtectedRoute from "./components/core/ProtectedRoute";
import urls from "./lib/urls";
import { authFlowUrls } from "./lib/urls";
import FakeDashboardTwo from "./pages/FakeDashboardTwo";
import CreateUsernameForm from "./components/auth/CreateUsernameForm";
import ResendEmailForm from "./components/auth/ResendEmailForm";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("Current path is " + location);
    if (authFlowUrls.includes(location.pathname)) {
      document.body.classList.add("space-background");
    }

    return () => {
      document.body.classList.remove("space-background");
    };
  }, [location]);

  const queryString = document.location.search;
  console.log("Rendering component: App");

  // check that we can query musixmatch
  const response2 = axios
    .get(API_BASE_URL + "/musixmatch/track.lyrics.get?track_id=15953433")
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

  return (
    <div>
      <NavBar />
      <Routes>
        {/* GENERAL */}
        <Route
          path="/"
          element={<Navigate to={urls.dashboard + queryString} replace />}
        />
        <Route
          path={urls.dashboard}
          element={
            <ProtectedRoute>
              <FakeDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={urls.dashboard + "two"}
          element={
            <ProtectedRoute>
              <FakeDashboardTwo />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />

        {/* AUTH RELATED */}
        <Route path={urls.login} element={<SignIn />} />
        <Route path={urls.register} element={<Register />} />
        <Route
          path={urls.registerUsername}
          element={
            <ProtectedRoute firebaseOnly={true}>
              <CreateUsernameForm />
            </ProtectedRoute>
          }
        />
        <Route path={urls.forgotPassword} element={<ForgotPassword />} />
        <Route path={urls.resendEmail} element={<ResendEmailForm />} />
        <Route path={urls.createNewPassword} element={<CreateNewPassword />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
