import classes from "./css/text.module.css";

const Label = ({ children, color, ...props }) => {
  let className = `${classes["body-medium"]} ${classes.label} `;
  if (color) {
    className += ` ${classes[color]}`
  }
  return (
    <label className={className} {...props}>
      {children}
    </label>
  );
};

export default Label;
