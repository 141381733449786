const NAME_SEPERATOR = "-!%#*-";

export const generateRandomString = function (length) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const joinNames = function (firstName, lastName) {
  return firstName + NAME_SEPERATOR + lastName;
}

const splitNames = function (fullName) {
  if (fullName.includes(NAME_SEPERATOR)) {
    return fullName.split(NAME_SEPERATOR);
  } else {
    return fullName.split(' ');
  }
}

export const getFirstName = function (fullName) {
  return splitNames(fullName)[0];
}
