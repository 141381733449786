import axios from "axios";
import auth from "./firebase-config";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/api/v1";
const USERS_ENDPOINT = "/users";
const SPOTIFY_ENDPOINT = "/spotify";
const GET_SPOTIFY_TOKEN_ENDPOINT = SPOTIFY_ENDPOINT + "/auth/token";
const VERIFY_USERNAME_ENDPOINT = USERS_ENDPOINT + "/verify_username";

const API = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const isUserTaken = async (field, value, abortController = null) => {
  const opts = { params: { [`${field}`]: value } };
  if (abortController) opts.signal = abortController.signal;
  const response = await API.get(USERS_ENDPOINT, opts);
  if (response.data.length) {
    return true;
  } else {
    return false;
  }
};

export const isUsernameTaken = async (username, abortController = null) => {
  return await isUserTaken("username", username, abortController);
};

export const isEmailTaken = async (email, abortController = null) => {
  return await isUserTaken("email", email, abortController);
};

export const createUser = async (userData) => {
  const idToken = await auth.currentUser.getIdToken(true);
  console.log(idToken);
  return (
    await API.post(USERS_ENDPOINT, userData, {
      headers: { "ID-TOKEN": idToken },
    })
  ).data;
};

export const deleteUser = async (uid) => {
  const idToken = await auth.currentUser.getIdToken(true);
  return await API.delete(USERS_ENDPOINT + "/" + uid, {
    headers: { "ID-TOKEN": idToken },
  });
};

export const getUser = async (uid) => {
  const idToken = await auth.currentUser.getIdToken(true);
  return (
    await API.get(`${USERS_ENDPOINT}/${uid}`, {
      headers: { "ID-TOKEN": idToken },
    })
  ).data;
};

// MUST ADD AUTHENTICATION TO THIS ENDPOINT
export const getSpotifyToken = async (authCode) => {
  return (
    await API.get(GET_SPOTIFY_TOKEN_ENDPOINT, { params: { code: authCode } })
  ).data.access_token;
};

export const getEmailByUsername = async (userData) => {
  console.log("Got this value:");
  console.log(userData);
  const opts = {
    params: { username: userData.username, password: userData.password },
  };
  try {
    return (await API.get(VERIFY_USERNAME_ENDPOINT, opts)).data;
  } catch (error) {
    if (error.response.data) {
      throw Error(error.response.data.detail);
    } else {
      throw error;
    }
  }
};
