import { TwitterAuthProvider } from "firebase/auth";
import TwitterIcon from "./TwitterIcon";
import SocialSignIn from "./SocialSignIn";

const TwitterSignIn = (props) => {
  return (
    <SocialSignIn icon={<TwitterIcon />} provider={new TwitterAuthProvider()} />
  );
};

export default TwitterSignIn;