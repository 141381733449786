import { getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import urls from "../../lib/urls";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../../lib/firebase-config";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import LoadingSpinner from "../ui/LoadingSpinner";
import { getUser } from "../../lib/api";

const ProtectedRoute = ({ children, firebaseOnly }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname;
  const status = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const verified = useSelector((state) => state.auth.verified);
  const provider = useSelector((state) => state.auth.provider);
  const existsInDb = useSelector((state) => state.auth.existsInDb);

  console.log("Rendering protected route");

  useEffect(() => {
    dispatch(authActions.setDestUrl(path));
  }, []);

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        console.log(`Redirect result was: ${result}`)
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        console.log('Error reading from redirect result')
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // // ...
      });

    const addAuthListener = () => {
      auth.onAuthStateChanged((user) => {
        console.log("Auth state has changed");
        console.log(user);
        if (user) {
          const provider = user.providerData[0].providerId;
          console.log("provider is" + provider);
          getUser(user.uid)
            .then((userData) => {
              dispatch(authActions.setAuthenticated(true));
              dispatch(authActions.setVerified(user.emailVerified));
              dispatch(authActions.setProvider(provider));
              dispatch(authActions.setExistsInDb(true));
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                dispatch(authActions.setAuthenticated(true));
                dispatch(authActions.setVerified(user.emailVerified));
                dispatch(authActions.setProvider(provider));
                dispatch(authActions.setExistsInDb(false));
              } else {
                // an actual error
                dispatch(authActions.setFailure(JSON.stringify(error)));
              }
            });
        } else {
          console.log("in else block");
          dispatch(authActions.setAuthenticated(false));
        }
      });
    };

    addAuthListener();
    return () => dispatch(authActions.setAuthenticated(null));
  }, []);

  useEffect(() => {
    if (status === "failure") {
      console.log(error);
      return () => dispatch(authActions.resetStatus());
    }
  }, [status, error]);

  console.log("current user in protected route");
  console.log(auth.currentUser);

  if (status === "failure") {
    return <p>{error}</p>;
  } else if (authenticated === null) {
    return <LoadingSpinner />;
  } else if (!authenticated) {
    return <Navigate to={urls.login} replace />;
  } else if (!existsInDb && !firebaseOnly) {
    return <Navigate to={urls.registerUsername} replace />;
  } else if (!verified && !firebaseOnly && provider === "password") {
    return <Navigate to={urls.resendEmail} replace />;
  }
  return children;
};

export default ProtectedRoute;
