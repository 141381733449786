import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailVerification,
  updateProfile,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import Link from "../core/Link";
import { validateEmail, validateFirstName, validateLastName } from "../../lib/validators";
import CenterAlignedCard from "../ui/CenterAlignedCard";
import OrLine from "./OrLine";
import SocialButtons from "./SocialButtons";
import useFormControl from "../../hooks/useFormControl";
import usePassword from "../../hooks/usePassword";
import Button from "../core/Button";
import { isEmailTaken } from "../../lib/api";
import LoadingSpinner from "../ui/LoadingSpinner";
import { authActions } from "../../store/auth-slice";
import EmailSent from "./EmailSent";
import auth from "../../lib/firebase-config";
import urls from "../../lib/urls";
import Text from "../core/Text";
import formStyles from "../core/css/Form.module.css";
import { joinNames } from "../../lib/utils";

const RegisterForm = (props) => {
  console.log("Rending RegisterForm");

  const dispatch = useDispatch();
  const status = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  const [emailTaken, setEmailTaken] = useState(false);

  const {
    formControl: firstName,
    resetInput: resetFirstName,
    error: firstNameError,
    inputValue: firstNameValue,
  } = useFormControl({
    inputId: "first-name-input",
    type: "text",
    label: "First Name*",
    validator: validateFirstName,
    size: "large",
  });

  const {
    formControl: lastName,
    resetInput: resetLastName,
    error: lastNameError,
    inputValue: lastNameValue,
  } = useFormControl({
    inputId: "last-name-input",
    type: "text",
    label: "Last Name*",
    validator: validateLastName,
    size: "large",
  });

  const {
    formControl: email,
    resetInput: resetEmail,
    error: emailError,
    inputValue: emailValue,
    inputTouched: emailTouched,
  } = useFormControl({
    inputId: "email-input",
    type: "email",
    label: "Email*",
    size: "large",
    validator: (value) => {
      if (emailTaken) {
        return "This email is already taken";
      } else {
        return validateEmail(value);
      }
    },
  });

  const { password, resetPassword, passwordError, passwordValue } = usePassword(
    "new",
    true
  );

  const disableSubmit =
    firstNameError ||
    lastNameError ||
    emailError ||
    passwordError ||
    status === "pending";

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      if (emailTouched) {
        try {
          const taken = await isEmailTaken(emailValue, controller);
          setEmailTaken(taken);
        } catch (error) {
          console.log(error);
        }
      }
    };
    const timer = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [emailValue, emailTouched]);

  useEffect(() => {
    if (status === "success") {
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_HOST}/`,
        handleCodeInApp: true,
      };
      sendEmailVerification(auth.currentUser, actionCodeSettings)
        .then(() => dispatch(authActions.setEmailSent()))
        .catch((error) => dispatch(authActions.setFailure(`${error}`)));
      resetFirstName();
      resetLastName();
      resetEmail();
      resetPassword();
    }
  }, [status]);

  useEffect(() => {
    if (status === "emailSent") {
      return () => dispatch(authActions.resetStatus());
    }
  }, [status]);

  useEffect(() => {
    if (status === "failure") {
      console.log(error);
      return () => dispatch(authActions.resetStatus());
    }
  }, [status, error]);

  const submitHandler = (event) => {
    console.log("in submit handler");
    event.preventDefault();
    dispatch(authActions.setPending());
    createUserWithEmailAndPassword(auth, emailValue, passwordValue)
      .then((userCredential) =>
        updateProfile(auth.currentUser, {
          displayName: joinNames(firstNameValue, lastNameValue),
        })
      )
      .then(() => {
        dispatch(authActions.setSuccess());
      })
      .catch((error) => {
        dispatch(authActions.setFailure(JSON.stringify(error)));
      });
  };

  let content = (
    <div className={formStyles["form-wrapper"]}>
      <form onSubmit={submitHandler}>
        <Text style="h5" className={"center " + formStyles.heading}>
          Create new account
        </Text>
        <Text
          style="body-medium"
          className={"center " + formStyles.subtitle}
          tag="p"
        >
          Already have an account? <Link to={urls.login}>Sign in</Link>
        </Text>
        <div className={formStyles["inputs-container"]}>
          {firstName}
          {lastName}
          {email}
          {password}
        </div>
        <Button size="large" disabled={disableSubmit}>
          Create account
        </Button>
        {status === "failure" && (
          <Text
            style="body-medium"
            color="error"
            className={"center " + formStyles["bottomheading"]}
          >
            A server error has occurred. Please try again later.
          </Text>
        )}
      </form>
      <OrLine />
      <SocialButtons />
      <Text
        style="body-small"
        className={"center fine-print " + formStyles["subheading"]}
      >
        By continuing, you agree to Lyra's{" "}
        <Link to="#">Terms {"&"} Conditions</Link> and{" "}
        <Link to="#">Privacy Policy</Link>, and our default{" "}
        <Link to="#">Notification Settings.</Link>
      </Text>
    </div>
  );

  if (status === "pending" || status === "success") {
    content = <LoadingSpinner></LoadingSpinner>;
  } else if (status === "emailSent") {
    const clickHandler = (event) => {
      console.log("inside click handler");
    };
    const text =
      "Please check your email to verify your account and get started.";
    content = <EmailSent onClick={clickHandler}>{text}</EmailSent>;
  }
  return <CenterAlignedCard>{content}</CenterAlignedCard>;
};

export default RegisterForm;
