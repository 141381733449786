export const validateEmail = (value) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return "";
  }
  return "Please enter a valid email";
};

export const validatePassword = (value) => {
  if (value.length < 8) {
    return "Password must be at least 8 characters";
  }
  return "";
};

export const validateUsernameOrEmail = (value) => {
  // place holder
  return "";
};

export const validateUsername = (value) => {
  if (value.length < 1) {
    return 'Please enter a username'
  } else if (value.length > 32) {
    return "Username cannot exceed 32 characters";
  } else if (value.includes("@")) {
    return "Username must not contain @ symbol"
  }
  return "";
};

export const validateFirstName = (value) => {
  if (!value.trim().length >= 1) {
    return "Please enter a first name";
  } else if (value.length > 80) {
    return "First name must be less than 80 characters";
  }
  return "";
};
export const validateLastName = (value) => {
  if (!value.trim().length >= 1) {
    return "Please enter a last name";
  } else if (value.length > 80) {
    return "Last name must be less than 80 characters";
  }
  return "";
};
