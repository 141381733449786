import PropTypes from "prop-types";
import classes from "./css/Input.module.css";
import textClasses from "./css/text.module.css";

const Input = ({ type, size, showError, disabled, icon, ...props }) => {
  let classNames = [textClasses["body-medium"], classes[type], classes[size]];
  if (showError) {
    classNames.push(classes.error)
  }
  if (icon) {
    classNames.push(classes.icon)
  }
  let className = classNames.join(" ");
  return (
    <div className={classes.container}>
      <input type={type} disabled={disabled} {...props} className={className} />
      {icon && icon}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(["text", "email", "password"]),
};

Input.defaultPropTypes = {
  type: "text",
};

export default Input;
