import Link from "../core/Link";
import CenterAlignedCard from "../ui/CenterAlignedCard";
import usePassword from "../../hooks/usePassword";
import Button from "../core/Button";
import urls from "../../lib/urls";
import formStyles from "../core/css/Form.module.css";

const CreateNewPasswordForm = (props) => {
  console.log("Rending CreateNewPasswordForm");

  const { password, resetPassword } = usePassword("new");
  const { confirmPassword, resetConfirmPassword } = usePassword("confirm");

  const submitHandler = (event) => {
    console.log("in submit handler");
    event.preventDefault();
    // change password
    // reset all fields here
  };

  return (
    <CenterAlignedCard>
      <div className={formStyles["form-wrapper"]}>
        <form onSubmit={submitHandler}>
          <h1 className="center">Create new password 🌱</h1>
          <p className="center">
            Please enter your new password. Your new password must be different
            from previously used passwords.
          </p>
          {password}
          {confirmPassword}
          <ul className="registration-hints fine-print">
            Your password must contain:
            <li>At least 8 characters</li>
            <li>Lowercase letters (a-z)</li>
            <li>Uppercase letters (A-Z)</li>
            <li>Numbers (0-9)</li>
          </ul>
          <Button>Change password</Button>
        </form>
        <p className="center">
          Already have an account? <Link to={urls.login}>Sign in</Link>
        </p>
      </div>
    </CenterAlignedCard>
  );
};

export default CreateNewPasswordForm;
