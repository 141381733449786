const urls = {
    dashboard: "/dashboard",
    login: "/signin",
    register: "/register",
    forgotPassword: "/forgotpassword",
    forgotPasswordSent: "forgotpasswordsent",
    createNewPassword: "/createnewpassword",
    registerUsername: "/registerUsername",
    resendEmail: "/resendemail",
}

export const authFlowUrls = [
    urls.login,
    urls.register,
    urls.forgotPassword,
    urls.forgotPasswordSent,
    urls.createNewPassword,
    urls.registerUsername,
    urls.resendEmail,
]

export default urls