import classes from "./css/Button.module.css";

const SocialButton = (props) => {
  const { children, ...passProps } = props;
  return (
    <button className={`${classes.button} ${classes.social}`} {...passProps}>
      {children}
    </button>
  );
};

export default SocialButton;
