import styles from "./css/OrLine.module.css";
import Text from "../core/Text";

const OrLine = (props) => {
  return (
    <div className={styles.group}>
      <span className={styles.line}></span>
      <Text style="subtitle-medium" className={styles.or} tag="span">OR</Text>
      <span className={styles.line}></span>
    </div>
  );
};

export default OrLine;