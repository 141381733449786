import { OAuthProvider } from "firebase/auth";
import AppleIcon from "./AppleIcon";
import SocialSignIn from "./SocialSignIn";

const AppleSignIn = (props) => {
  return (
    <SocialSignIn
      icon={<AppleIcon />}
      provider={new OAuthProvider("apple.com")}
    />
  );
};

export default AppleSignIn;
