import { useState } from "react";
import { validatePassword } from "../lib/validators";
import useFormControl from "./useFormControl";

const usePassword = (type, asterisk = false) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  let eyeClasses = "fa-solid fa-eye";
  if (passwordVisible) {
    eyeClasses += " fa-eye-slash";
  }

  const togglePasswordHandler = (props) => {
    setPasswordVisible((state) => !state);
  };
  let placeholder
  if (type === "existing") {
    placeholder = ""
  } else {
    placeholder = "Password (8+ characters)"
  }

  const extraContent = (
    <>
      {/* <i className={eyeClasses} id="eye" onClick={togglePasswordHandler}></i> */}
    </>
  );

  const {
    formControl: password,
    resetInput: resetPassword,
    error: passwordError,
    inputValue: passwordValue,
  } = useFormControl({
    inputId: "password",
    type: passwordVisible ? "text" : "password",
    label: asterisk ? "Password*" : "Password",
    placeholder: placeholder,
    validator: validatePassword,
    extraContent,
    size: "large"
  });

  return { password, resetPassword, passwordError, passwordValue };
};

export default usePassword;
