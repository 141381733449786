import { Link as RouterLink } from "react-router-dom";
import classes from "./css/Button.module.css";
import textClasses from "./css/text.module.css";
import PropTypes from "prop-types";

const Button = ({
  children,
  type = "primary",
  size = "medium",
  linkType,
  fill = "filled",
  disabled,
  ...props
}) => {
  const className = `${textClasses["body-medium"]} ${classes[fill]} ${classes.button} ${classes[type]} ${classes[size]}`;

  if (linkType === "internal") {
    return (
      <RouterLink className={className} disabled={disabled} {...props}>
        {children}
      </RouterLink>
    );
  } else if (linkType === "external") {
    return (
      <a className={className} disabled={disabled} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <button className={className} disabled={disabled} {...props}>
        {children}
      </button>
    );
  }
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "danger"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fill: PropTypes.oneOf(["filled", "ghost"]).isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: "primary",
  size: "medium",
  fill: "filled",
  disabled: false,
};

export default Button;
