import { signInWithRedirect } from "firebase/auth";
import SocialButton from "../core/SocialButton";
import auth from "../../lib/firebase-config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";

const SocialSignIn = ({ icon, provider }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const destUrl = useSelector((state) => state.auth.destUrl);

  const loginHandler = (event) => {
    signInWithRedirect(auth, provider)
      // .then((result) => {
      //   navigate(destUrl);
      // })
      // .catch((error) => {
      //   dispatch(authActions.setFailure(JSON.stringify(error)));
      // });
  };
  return <SocialButton onClick={loginHandler}>{icon}</SocialButton>;
};

export default SocialSignIn;
