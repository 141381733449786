import Potato from "./Potato";
import { signOut } from "firebase/auth";
import auth from "../../lib/firebase-config";

const NavBar = (props) => {
  const clickHandler = (event) => {
    signOut(auth)
      .then(() => {
        console.log("successfully logged out");
      })
      .catch((error) => {
        console.log("error during logout");
      });
  };

  return (
    <nav>
      <Potato />
      <button onClick={clickHandler}>Log out</button>
    </nav>
  );
};

export default NavBar;
