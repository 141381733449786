const AppleIcon = (props) => {
  return (
    <div id="apple-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.2874 31.1721C35.677 32.5696 34.9544 33.856 34.1173 35.0387C32.9761 36.651 32.0418 37.767 31.3217 38.3867C30.2055 39.404 29.0095 39.925 27.7289 39.9546C26.8095 39.9546 25.7007 39.6953 24.4101 39.1694C23.1152 38.646 21.9252 38.3867 20.8372 38.3867C19.696 38.3867 18.4722 38.646 17.1631 39.1694C15.852 39.6953 14.7959 39.9694 13.9883 39.9966C12.7602 40.0484 11.5361 39.5126 10.3143 38.3867C9.5344 37.7127 8.55895 36.5572 7.3904 34.9202C6.13664 33.1721 5.10588 31.145 4.29836 28.8339C3.43353 26.3377 3 23.9205 3 21.5803C3 18.8997 3.58452 16.5877 4.75531 14.6502C5.67545 13.0939 6.89956 11.8663 8.43163 10.9651C9.9637 10.0639 11.6191 9.60465 13.4018 9.57527C14.3773 9.57527 15.6564 9.87427 17.2461 10.4619C18.8312 11.0515 19.849 11.3505 20.2953 11.3505C20.6289 11.3505 21.7595 11.0009 23.6763 10.3039C25.4889 9.65749 27.0188 9.38984 28.272 9.49527C31.668 9.76687 34.2194 11.0935 35.9162 13.4835C32.8789 15.3072 31.3765 17.8614 31.4064 21.1381C31.4338 23.6904 32.3682 25.8143 34.2045 27.5007C35.0366 28.2833 35.966 28.8883 37 29.3179C36.7758 29.9623 36.5391 30.5796 36.2874 31.1721ZM28.4988 0.800228C28.4988 2.80068 27.7612 4.6685 26.2912 6.39734C24.5172 8.45259 22.3715 9.64021 20.0446 9.4528C20.0149 9.21281 19.9978 8.96023 19.9978 8.6948C19.9978 6.77437 20.8414 4.71912 22.3396 3.03868C23.0876 2.18784 24.0388 1.48038 25.1924 0.916026C26.3435 0.360092 27.4324 0.0526484 28.4564 0C28.4863 0.26743 28.4988 0.534877 28.4988 0.800202V0.800228Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default AppleIcon