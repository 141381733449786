import { FacebookAuthProvider } from "firebase/auth";
import FacebookIcon from "./FacebookIcon";
import SocialSignIn from "./SocialSignIn";

const FacebookSignIn = (props) => {
  return (
    <SocialSignIn icon={<FacebookIcon />} provider={new FacebookAuthProvider()} />
  );
};

export default FacebookSignIn;
