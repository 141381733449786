import Link from "../core/Link";
import CenterAlignedCard from "../ui/CenterAlignedCard";
import useFormControl from "../../hooks/useFormControl";
import Button from "../core/Button";
import urls from "../../lib/urls";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isEmailTaken } from "../../lib/api";
import auth from "../../lib/firebase-config";
import { authActions } from "../../store/auth-slice";
import { sendPasswordResetEmail } from "firebase/auth";
import EmailSent from "./EmailSent";
import LoadingSpinner from "../ui/LoadingSpinner";
import Text from "../core/Text";
import formStyles from "../core/css/Form.module.css";

const RegisterForm = (props) => {
  console.log("Rending ForgotPasswordForm");

  const dispatch = useDispatch();
  const status = useSelector((state) => state.auth.status);
  let error = useSelector((state) => state.auth.error);

  const [emailExists, setEmailExists] = useState(null);

  const submitHandler = (event) => {
    console.log("in submit handler");
    event.preventDefault();
    dispatch(authActions.setPending());
    sendPasswordResetEmail(auth, emailValue)
      .then(() => {
        dispatch(authActions.setEmailSent());
      })
      .catch((error) => {
        console.log(error);
        dispatch(authActions.setFailure(JSON.stringify(error)));
      });
  };

  useEffect(() => {
    if (["failure", "emailSent"].includes(status)) {
      return () => {
        console.log("resetting status in forgot password effect cleanup");
        dispatch(authActions.resetStatus());
      };
    }
  }, [status]);

  const {
    formControl: email,
    resetInput: resetEmail,
    error: emailError,
    inputValue: emailValue,
    inputTouched: emailTouched,
  } = useFormControl(
    {
      inputId: "email-input",
      type: "email",
      label: "Email *",
      size: "large",
      validator: (value) => {
        if (emailExists === false && value.trim() !== "") {
          return "User not found with this email address";
        }
      },
    });

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      if (emailValue.trim() !== "") {
        try {
          const taken = await isEmailTaken(emailValue, controller);
          console.log("Settign email taken", taken);
          setEmailExists(taken);
        } catch (error) {
          console.log(error);
        }
      }
    };
    const timer = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [emailValue, emailTouched]);

  const disableSubmit =
    emailExists !== true || emailValue.trim() === "" || status == "pending";

  if (process.env.REACT_APP_DEBUG !== "true") {
    error = "An error has occurred. Please try again later.";
  }

  let content = (
    <div className={formStyles["form-wrapper"]}>
      <form onSubmit={submitHandler}>
        <Text style="h5" className={"center " + formStyles.heading}>
          Forgot password? 🧐
        </Text>
        <Text
          style="body-medium"
          className={"center " + formStyles.subheading}
          tag="p"
        >
          No worries at all! Enter your email and we'll send you a link to reset
          your password
        </Text>
        <div className={formStyles["inputs-container"]}>{email}</div>
        <Button size="large" disabled={disableSubmit}>
          Send link to email
        </Button>
        {status === "failure" && (
          <Text style="body-small" color="error" className="center">
            {error}
          </Text>
        )}
      </form>
      <Text
        style="body-medium"
        className={"center " + formStyles.bottomheading}
      >
        New to Lyra? <Link to={urls.register}>Create a free account</Link>
        <br></br>
        Already have an account? <Link to={urls.login}>Sign in</Link>
      </Text>
    </div>
  );

  if (status === "emailSent") {
    content = (
      <EmailSent>
        Check your email to continue resetting your password.
      </EmailSent>
    );
  } else if (status === "pending") {
    content = <LoadingSpinner />;
  }

  return <CenterAlignedCard>{content}</CenterAlignedCard>;
};

export default RegisterForm;
