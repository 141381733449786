import { PropTypes } from "prop-types";

import Input from "./Input";
import Label from "./Label";
import Text from "./Text";
import formStyles from "./css/Form.module.css";
import inputStyles from "./css/Input.module.css";

const FormInput = ({
  inputId,
  type,
  label,
  placeholder,
  inputValue,
  onChange,
  onBlur,
  onFocus,
  error,
  showError,
  extraContent,
  hint,
  size,
  disabled,
  icon,
}) => {
  return (
    <div className={formStyles.control}>
      <Label htmlFor={inputId} color="grey">
        {label}
      </Label>
      <Input
        id={inputId}
        type={type}
        placeholder={placeholder}
        showError={showError}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        size={size}
        disabled={disabled}
        icon={icon}
      />
      {hint && (
        <Text style="body-small" color="grey" className={inputStyles.hint}>
          {hint}
        </Text>
      )}
      {showError && (
        <Text
          color="error"
          style="body-small"
          className={formStyles["input-error"]}
        >
          {error}
        </Text>
      )}
      {extraContent && extraContent}
    </div>
  );
};

export default FormInput;

FormInput.propTypes = {
  type: PropTypes.oneOf(["text", "email", "password"]),
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

FormInput.defaultProps = {
  type: "text",
  showError: false,
  disabled: false,
  label: "Label",
  size: "medium",
};
