import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUser, getEmailByUsername } from "../lib/api";
import urls from "../lib/urls";

export const createUserThunk = createAsyncThunk("auth/createUser", createUser);
export const getEmailByUsernameThunk = createAsyncThunk(
  "auth/getEmail",
  getEmailByUsername
);

const initialState = {
  status: "idle",
  error: null,
  emailFromUsername: null,
  destUrl: urls.dashboard,
  authenticated: null,
  verified: null,
  existsInDb: null,
  provider: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmailSent(state, action) {
      state.status = "emailSent";
    },
    setFailure(state, action) {
      state.status = "failure";
      state.error = action.payload;
    },
    setSuccess(state, action) {
      state.status = "success";
      state.error = null;
    },
    setPending(state, action) {
      state.status = "pending";
      state.error = null;
    },
    resetStatus(state, action) {
      state.status = "idle";
      state.error = null;
    },
    setEmailFromUsername(state, action) {
      state.emailFromUsername = action.payload;
    },
    setAuthenticated(state, action) {
      state.authenticated = action.payload;
    },
    setVerified(state, action) {
      state.verified = action.payload;
    },
    setProvider(state, action) {
      state.provider = action.payload;
    },
    setExistsInDb(state, action) {
      state.existsInDb = action.payload;
    },
    setDestUrl(state, action) {
      state.destUrl = action.payload;
    },
    resetDestUrl(state, action) {
      state.destUrl = urls.dashboard;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createUserThunk.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    });
    builder.addCase(createUserThunk.fulfilled, (state, action) => {
      state.status = "success";
      state.error = null;
    });
    builder.addCase(createUserThunk.rejected, (state, action) => {
      state.status = "failure";
      state.error = action.error.message;
    });
    builder.addCase(getEmailByUsernameThunk.rejected, (state, action) => {
      state.status = "failure";
      state.error = action.error.message;
    });
    builder.addCase(getEmailByUsernameThunk.pending, (state, action) => {
      state.status = "pending";
      state.error = null;
    });
    builder.addCase(getEmailByUsernameThunk.fulfilled, (state, action) => {
      state.emailFromUsername = action.payload;
      state.error = null;
    });
  },
});

export const authActions = authSlice.actions;

export default authSlice;
