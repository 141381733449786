import styles from "./Potato.module.css"

const Potato = (props) => {
  return (
    <a className={styles.potato} target="_blank" href="https://www.figma.com/file/EaU1cqeCG6k4N2YbqyPz50/%F0%9F%8E%A8-Design-system?node-id=103%3A1205">
      🥔
    </a>
  );
};

export default Potato;