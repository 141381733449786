import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";

const auth = getAuth();

const FakeDashboardTwo = (props) => {
  const [name, setName] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setName(user.displayName);
        setEmailVerified(user.emailVerified);
        console.log(user)
        console.log(user.emailVerified)
      }
    });
  }, []);
  return (
    <>
      <h1>Welcome, {name}</h1>
      <p>THIS IS DASHBOARD#2</p>
      <p>Email verified: {`${emailVerified}`}</p>
    </>
  );
};

export default FakeDashboardTwo;
