import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registrationMode: false,
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleMode(state) {
      state.registrationMode = ! state.registrationMode;
    }
  }

})

export const uiActions = uiSlice.actions;

export default uiSlice;