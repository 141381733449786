import FormInput from "../components/core/FormInput";
import useInput from "./useInput";
import { useEffect } from "react";

const useFormControl = ({
  inputId,
  label,
  type,
  placeholder,
  extraContent,
  validator,
  size,
}) => {
  const {
    value: inputValue,
    isTouched: inputTouched,
    error,
    showError,
    valueChangeHandler: inputValueChangeHandler,
    inputFocusHandler,
    inputBlurHandler,
    reset: resetInput,
  } = useInput(validator);

  const formControl = (
    <FormInput
      {...{
        inputId,
        type,
        label,
        placeholder,
        inputValue: inputValue,
        onChange: inputValueChangeHandler,
        onBlur: inputBlurHandler,
        onFocus: inputFocusHandler,
        error,
        showError,
        extraContent,
        size
      }}
    />
  );

  return { formControl, resetInput, error, inputValue, inputTouched };
};

export default useFormControl;
