import { useEffect } from "react";
import CenterAlignedCard from "../ui/CenterAlignedCard";
import Button from "../core/Button";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../ui/LoadingSpinner";
import { sendEmailVerification } from "firebase/auth";
import { authActions } from "../../store/auth-slice";
import EmailSent from "./EmailSent";
import auth from "../../lib/firebase-config";
import Text from "../core/Text";
import formStyles from "../core/css/Form.module.css"

const ResendEmailForm = (props) => {
  console.log("Rending ResetEmailForm");

  const dispatch = useDispatch();
  const status = useSelector((state) => state.auth.status);
  const submitHandler = (event) => {
    console.log("in submit handler");
    event.preventDefault();
    dispatch(authActions.setPending())
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_HOST}/`,
      handleCodeInApp: true,
    };
    sendEmailVerification(auth.currentUser, actionCodeSettings)
      .then(() => dispatch(authActions.setEmailSent()))
      .catch((error) => dispatch(authActions.setFailure(`${error}`)));
  };

  useEffect(() => {
    if (status === "emailSent") {
      return () => dispatch(authActions.resetStatus());
    }
  }, [status]);

  let content = (
    <div className={formStyles["form-wrapper"]}>
      <form onSubmit={submitHandler}>
      <Text style="h5" className={"center " + formStyles.heading}>Email Verification Required</Text>
      <Text style="body-medium" className={"center " + formStyles["subheading"]}>It looks like your email hasn't been verified yet! Check your email
          and click on the sign in link to continue.</Text>
        <Button size="large">Resend link to email</Button>
        {status === "failure" && (
          <Text style="body-small" color="error" className="center">
            An error has occurred. Please try again later.
          </Text>
        )}
      </form>
    </div>
  );

  if (status === "pending") {
    content = <LoadingSpinner></LoadingSpinner>;
  } else if (status === "emailSent") {
    const clickHandler = (event) => {
      console.log("inside click handler");
    };
    const text =
      "Please check your email to verify your account and get started.";
    content = <EmailSent onClick={clickHandler}>{text}</EmailSent>;
  }

  return <CenterAlignedCard>{content}</CenterAlignedCard>;
};

export default ResendEmailForm;
