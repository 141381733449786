import AppleSignIn from "./AppleSignIn";
import FacebookSignIn from "./FacebookSignIn";
import GoogleSignIn from "./GoogleSignIn";
import TwitterSignIn from "./TwitterSignIn";
import classes from "../core/css/Button.module.css";

const SocialButtons = (props) => {
  return (
    <div className={classes["social-group"]}>
      <GoogleSignIn />
      <AppleSignIn />
      <FacebookSignIn />
      <TwitterSignIn />
    </div>
  );
};

export default SocialButtons;
