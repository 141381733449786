import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import Link from "../core/Link";
import { validateUsernameOrEmail } from "../../lib/validators";
import CenterAlignedCard from "../ui/CenterAlignedCard";
import OrLine from "./OrLine";
import SocialButtons from "./SocialButtons";
import useFormControl from "../../hooks/useFormControl";
import usePassword from "../../hooks/usePassword";
import Button from "../core/Button";
import { isEmailTaken, isUsernameTaken } from "../../lib/api";
import { authActions, getEmailByUsernameThunk } from "../../store/auth-slice";
import auth from "../../lib/firebase-config";
import LoadingSpinner from "../ui/LoadingSpinner";
import urls from "../../lib/urls";
import Text from "../core/Text";
import formStyles from "../core/css/Form.module.css";

const LoginForm = (props) => {
  console.log("Rending LoginForm");
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.emailFromUsername);
  const status = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  const destUrl = useSelector((state) => state.auth.destUrl);
  const navigate = useNavigate();

  const { password, resetPassword, passwordError, passwordValue } =
    usePassword("existing");

  const [usernameOrEmailExists, setUsernameOrEmailExists] = useState(true);

  const {
    formControl: usernameOrEmail,
    resetInput: resetUsernameOrEmail,
    error: usernameOrEmailError,
    inputValue: usernameOrEmailValue,
    inputTouched: usernameOrEmailTouched,
  } = useFormControl({
    inputId: "username-email",
    type: "text",
    label: "Username or email",
    size: "large",
    validator: (value) => {
      if (!usernameOrEmailExists) {
        return "User not found with this username or email";
      } else {
        return validateUsernameOrEmail(value);
      }
    },
  });

  const disableSubmit =
    usernameOrEmailError || passwordError || status === "pending";

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      if (usernameOrEmailTouched) {
        const isTakenFunc = usernameOrEmailValue.includes("@")
          ? isEmailTaken
          : isUsernameTaken;
        try {
          const taken = await isTakenFunc(usernameOrEmailValue, controller);
          setUsernameOrEmailExists(taken);
        } catch (error) {
          console.log(error);
        }
      }
    };
    const timer = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [usernameOrEmailValue, usernameOrEmailTouched]);

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(authActions.setPending());
    if (usernameOrEmailValue.includes("@")) {
      dispatch(authActions.setEmailFromUsername(usernameOrEmailValue));
    } else {
      dispatch(
        getEmailByUsernameThunk({
          password: passwordValue,
          username: usernameOrEmailValue,
        })
      );
    }
  };

  useEffect(() => {
    if (email) {
      signInWithEmailAndPassword(auth, email, passwordValue)
        .then((userCredential) => userCredential.user.getIdToken(false))
        .then(() => dispatch(authActions.setSuccess()))
        .catch((error) => dispatch(authActions.setFailure(`${error}`)));
    }
  }, [email, passwordValue]);

  useEffect(() => {
    if (status === "success") {
      dispatch(authActions.setEmailFromUsername(null));
      resetPassword();
      resetUsernameOrEmail();
      navigate(destUrl);
      return () => dispatch(authActions.resetStatus());
    }
  }, [status]);

  useEffect(() => {
    if (status === "failure") {
      dispatch(authActions.setEmailFromUsername(null));
      console.log(error);
      return () => dispatch(authActions.resetStatus());
    }
  }, [status]);

  let content = (
    <div className={formStyles["form-wrapper"]}>
      <form onSubmit={submitHandler}>
        <Text style="h5" className={"center " + formStyles.heading}>
          Sign in to Lyra 🚀
        </Text>
        <Text
          style="body-medium"
          className={"center " + formStyles.subtitle}
          tag="p"
        >
          New to Lyra? <Link to={urls.register}>Create a free account</Link>
        </Text>
        <div className={formStyles["inputs-container"]}>
          {usernameOrEmail}
          {password}
        </div>
        <Button disabled={disableSubmit} size="large">
          Sign in
        </Button>
        {status === "failure" && (
          <Text
            style="body-medium"
            color="error"
            className={"center " + formStyles["subheading"]}
          >
            A server error has occurred. Please try again later.
          </Text>
        )}
        <Text style="body-medium" className={"center " + formStyles.forgot}>
          <Link to="/forgotpassword">Forgot password?</Link>
        </Text>
      </form>
      <OrLine />
      <SocialButtons />
    </div>
  );

  if (status === "pending") {
    content = <LoadingSpinner></LoadingSpinner>;
  }

  return <CenterAlignedCard>{content}</CenterAlignedCard>;
};

export default LoginForm;
