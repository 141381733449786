import classes from "./Cards.module.css";
import { useRef, useEffect, useState } from "react";

const CenterAlignedCard = (props) => {
  const cardRef = useRef(null);
  const [cardHeight, setCardHeight] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);

  const css = {
    top: "max(50% - calc(" + cardHeight + "px / 2), 0px)",
    left: "max(50% - calc(" + cardWidth + "px / 2), 0px)",
    transform: "inherit"
  }

  useEffect(() => {
    console.log('Inside card useEffect');
    setCardHeight(cardRef.current.offsetHeight);
    setCardWidth(cardRef.current.offsetWidth);
  }, []);

  if (cardHeight !== 0) {
    return <div ref={cardRef} style={css} className={classes["center-aligned-card"]}>{props.children}</div>;
  }
  return <div ref={cardRef} className={classes["center-aligned-card"]}>{props.children}</div>;

};

export default CenterAlignedCard;
