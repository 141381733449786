import PropTypes from "prop-types";
import textStyles from "./css/text.module.css";

const headings = ["h1", "h2", "h3", "h4", "h5", "h6"];

const Text = ({ tag, style, color, children, ...props }) => {
  let Tag;
  if (tag) {
    Tag = tag;
  } else if (headings.includes(style)) {
    Tag = style;
  } else {
    Tag = "p";
  }

  let classes = [textStyles[style]];
  if (color) {
    classes.push(textStyles[color]);
  }
  if (props.className) {
    classes.push(props.className);
  }
  return <Tag className={classes.join(" ")}>{children}</Tag>;
};

Text.propTypes = {
  style: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle-large",
    "subtitle-medium",
    "subtitle-small",
    "body-large",
    "body-medium",
    "body-small",
  ]).isRequired,
};

export default Text;
